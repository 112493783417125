/* SECTION COMPONENT STYLES */

$section-heading-color: $accent;

.section:last-of-type {
  .section-content {
    > .section-graf:last-of-type {
      color: $accent;
      font: {
        size: px-to-em(20);
        weight: 700; }
      margin-bottom: 0; } } }

.section-content {
  @include flex(column); }

.section-heading {
  color: $section-heading-color;
  font: {
    family: $font-stack-special;
    size: px-to-em(20);
    weight: 700; }
  margin-top: 0;
  text-transform: uppercase; }

.section-graf {
  font-size: px-to-em(15);

  @include respond-to(xlarge, min) {
    margin: 0 0 px-to-em(24) 0; }


  @include respond-to(schmedium) {
    font-size: px-to-em(14); }


  margin: 0 0 px-to-em(20); }

.section-pullquote {
  @include respond-to(schmedium) {
    font-size: px-to-em(25); }


  @include respond-to(small) {
    font-size: px-to-em(20); }


  border-left: 5px solid $med2;
  color: rgba($accent, 0.5);
  font: {
    weight: 700;
    style: italic;
    size: px-to-em(30); }
  margin: 0 0 px-to-em(12) 0;
  padding: px-to-em(8) px-to-em(16);
  text-align: left; }
