/* FOOTER STYLES */

$footer-border: px-to-em(0.5) solid $med2;
$footer-font-size: px-to-em(14);
$footer-link-margin: 0 0 0 1em;

footer {
  border-top: $footer-border;
  padding: px-to-em(17 * 2) 0;
  width: 100vw;

  .container {
    @include flex(column); } }

.footer-title,
.footer-column-link,
.footer-list-link,
.editors {
  font-size: $footer-font-size; }

.footer-title,
.footer-column-link {
  font-weight: 700; }

.footer-title {
  margin: px-to-em(30) 0; }

.footer-column:not(:last-of-type) {
  @include respond-to(schmedium) {
    margin-bottom: px-to-em(17); } }

.categories,
.contact-editors {
  @include flex(row, flex-start, space-between);
  @include respond-to(schmedium) {
    flex-direction: column; } }

.categories {
  border-bottom: $footer-border;
  padding-bottom: px-to-em(17 * 2);
  width: 100%; }

.footer-column-link {
  color: $accent; }

.footer-list-link {
  color: $dark;
  font-weight: 400;

  &:hover {
    font-weight: 600; }

  &:not(:first-of-type) {
    margin: $footer-link-margin; } }

.contact-editors {
  padding-top: px-to-em(17 * 2); }

.editors {
  @include respond-to(schmedium, min) {
 } }    // text-align: right

.contact-title-link {
  color: $accent !important;
  font: {
    size: $footer-font-size;
    weight: 700 !important; }
  margin: 0; }

.contact-phone {
  font-size: $footer-font-size;

  &:not(:last-of-type) {
    margin: 0 0 0.5em; }

  a {
    @include hocus {
      color: $accent; }


    color: $dark;
    font-weight: 700; } }

.stay-connected-copyright {
  @include flex(row, flex-end, space-between);
  @include respond-to(schmedium) {
    @include flex(column, flex-start, space-between); }


  font-size: $footer-font-size;
  margin-top: 1em;

  a {
    color: $dark;

    &:hover {
      color: $accent; } } }

.editors {
  span {
    color: $accent;
    font-weight: 600; }

  dl {
    &:not(:last-of-type) {
      margin: 0 0 0.375em; }

    span {
      color: $dark; } }

  dl:last-of-type {
    margin-bottom: 0; } }

.stay-connected {
  @include flex(row, baseline, flex-start);

  @include respond-to(schmedium) {
    flex-direction: column;
    align-items: stretch; }


  p,
  .nav-link:not(:last-of-type) {
    margin: 0 0.5em 0 0; }

  p,
  .nav-link {
    @include respond-to(schmedium) {
      margin: 0; } }

  p {
    font-weight: 700; }

  .nav-link:hover {
    color: $accent; } }

.copyright {
  @include flex(column, flex-end, flex-start);
  @include respond-to(schmedium) {
    align-items: flex-start;
    margin-top: 1em; }


  color: $dark;
  font-weight: 700;

  &:hover {
    font-weight: 700; } }

.copyright-lab,
.credit-developer {
  @include hocus {
    font-weight: 700 !important; } }

.copyright-lab {
  margin-bottom: px-to-em(5); }

.credit-developer {}

.credit-developer {
  color: rgba($med, 0.85) !important;

  @include hocus {
    color: $accent !important; } }
