/* NAVIGATION LAYOUT COMPONENT STYLES */

#navigation {
  position: absolute;
  z-index: 5; }

nav {
  @include respond-to(schmedium) {
    background-color: $dark;
    padding: 1em 0;
    right: 0;
    top: 0;
    width: 100%; }


  position: fixed;
  right: 1%;
  top: px-to-em(85);

  > div {
    @include flex(column, flex-end);
    @include respond-to(schmedium) {
      @include flex(row, center, space-between);

      overflow-x: scroll; }


    max-width: $max-width;

    .fa-location-arrow {
      @include respond-to(schmedium) {
        margin: 0 px-to-em(85 * 0.125) 0 0; }


      color: $accent;
      font-size: px-to-em(26);
      margin: 0 px-to-em(10) px-to-em(12.5) 0; } } }

.navigation-link {
  @include flex(row-reverse, center);

  @include respond-to(schmedium) {
    @include flex(row);

    margin: 0 px-to-em(85 * 0.25) 0 0;

    > a {
      font-size: px-to-em(10);
      height: auto;
      opacity: 1;
      margin: 0 0 0 px-to-em(7.5); } }


  .container {
    @include flex(column, flex-end); }

  > div {
    @include flex(column, center, center);
    @include respond-to(schmedium) {
      + a {
        height: auto !important;
        opacity: 1 !important;
        margin: 0 0 0 px-to-em(7.5); } } }

  > a {
    @include respond-to(schmedium, min) {
      height: 0;
      margin: 0;
      opacity: 0; }


    color: $light;
    font-size: px-to-em(12);
    text-align: center; } }

.navigation-link-circle {
  @include respond-to(schmedium) {
    border: 3px solid $accent;
    margin: 0;

    &.active,
    &:hover {
      background-color: transparent !important;
      border-color: $accent;

      + a {
        color: $accent; } } }


  background-color: transparent;
  border: 3px solid $accent;
  border-radius: 50%;
  height: px-to-em(14);
  margin: 0 px-to-em(20);
  width: px-to-em(14);

  + a {
    color: $med; }

  &.active,
  &:hover {
    background-color: $accent; } }

.navigation-link-line {
  @include respond-to(schmedium) {
    height: 0; }


  height: px-to-em(50);
  border-left: 1px solid $med2; }
