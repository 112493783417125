/* GENERAL STYLES - BASE/COMMON */

* {
  @include transition; }

*,
html {
  box-sizing: border-box; }

 ::-webkit-scrollbar {
  display: none; }

body {
  background-color: $light;
  font-family: $font-stack-regular;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
 }  // font-family: $font-stack-special

a,
p,
dl,
ul {
 }  // font-family: $font-stack-regular

a,
button {
  @include hocus {
    cursor: pointer; } }

a {
  @include hocus {
    font-weight: 600; }


  text-decoration: none; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

button {
  border: none;
  outline: none; }

input {
  outline: none; }

dd {
  margin: 0; }
