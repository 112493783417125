// GLOBAL SASS VARIABLES

// colors
/// black
/// @type color
$dark: rgba(0, 0, 0, 1);

/// gray
/// @type color
$med: rgba(155, 155, 155, 1);

/// gray
/// @type color
$med2: rgba(222, 222, 222, 1);

/// off white
/// @type color
$light: rgba(249, 250, 252, 1);

/// dbk red
/// @type color
$accent: rgba(229, 29, 55, 1);

// fonts
/// special font stack
/// @type List
$font-stack-special: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif;

/// regular font stack
/// @type List
$font-stack-regular: 'IBM Plex Sans', sans-serif;

/// code (monospace) font family
/// @type List
$code-font-stack: Courier New, DejaVu Sans Mono, Bitstream Vera Sans Mono, Monaco, monospace !default;

// elements
/// container's maximum width
/// @type percentage
$max-width: 87.5% !default;

// paths
/// url where all assets are served from
/// @type string
$base-url: '../../assets/';

// breakpoints
/// xsmall breakpoint
/// @type length
$breakpoint-xsmall: 320px;

/// small breakpoint
/// @type length
$breakpoint-small: 480px;

/// medium breakpoint
/// @type length
$breakpoint-schmedium: 768px;

/// second medium breakpoint
/// @type length
$breakpoint-medium: 980px;

/// large breakpoint
/// @type length
$breakpoint-large: 1200px;

/// xlarge breakpoint
/// @type length
$breakpoint-xlarge: 1440px;

/// breakpoints map
/// @prop {string} keys - identifiers mapped to a given length
/// @prop {Map} values - actual breakpoints expressed in ems
$breakpoints: ('xsmall': $breakpoint-xsmall, 'small': $breakpoint-small, 'schmedium': $breakpoint-schmedium, 'medium': $breakpoint-medium, 'large': $breakpoint-large, 'xlarge': $breakpoint-xlarge);
