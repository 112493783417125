/* LANDING LAYOUT COMPONENT STYLES */

.landing {
  @include set-bkg-img('landing-bkg.png');
  @include flex(column, flex-start, flex-end);

  height: 100vh;
  padding-bottom: px-to-em(85);
  width: 100vw;

  h1, h2 {
    margin: 0; }

  h1 {
    @include respond-to(schmedium) {
      font-size: px-to-em(25); }


    @include respond-to(small) {
      font-size: px-to-em(22); }


    color: $light;
    font-size: px-to-em(36); }

  h2 {
    @include respond-to(schmedium) {
      font-size: px-to-em(22); }


    @include respond-to(small) {
      font-size: px-to-em(17); }


    color: $accent;
    font: {
      size: px-to-em(25);
      weight: 400; }
    text-transform: uppercase; } }

.blurb {
  @include respond-to(schmedium) {
    width: 100%; }


  color: rgba($light, 0.7);
  font-size: px-to-em(14);
  width: 50%; }

.title {
  @include flex(column-reverse); }

.credit {
  @include flex(column);

  color: $light;
  font-size: px-to-em(14);
  margin-top: px-to-em(5);

  p {
    margin: 0; }

  a {
    @include respond-to(schmedium) {
      text-decoration-color: $light; }


    color: $light;
    font-weight: 600;

    &:hover {
      color: $accent; } } }
